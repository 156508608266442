import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import FormTable from '../../../components/FormTables/Table';
import SwitchToggle from '../../../components/SwitchToggle';
import { FeedbackContext } from '../../../store/context/FeedbackContext';
import { CSVLink } from "react-csv";

import { SpeedDial } from '@material-ui/lab';
import { SpeedDialIcon } from '@material-ui/lab';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';

import { getFeedbackList, updateFeedback } from '../../../services/authService';

export default function Page(props) {
    const { setSnack, setLoader } = props;
    const [ open, setOpen ] = useState(false);
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();
    const [ exportData, setExportData ] = useState([]);
    const feedback = useContext(FeedbackContext);

    useEffect(() => {
        if(data.data !== undefined) {
            let result = [];
            data.data.map((item, key) => {
                result.push(
                    {
                        "#": item.id,
                        "狀態": item.status === 1 ? "已處理" : "尚未處理",
                        "會員編號": item.username,
                        "聯絡人": item.contents.contact_name,
                        "聯絡電話": item.contents.contact_phone,
                        "Email": item.contents.contact_email,
                        "姓名 (先生 / 小姐)": item.contents.name,
                        "來電序號 *(取件序號)": item.contents.order_num,
                        "建立時間": item.created_at,
                    }
                )
            })
            setExportData(result);
        }
    }, [data])

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'status',
            name: "狀態",
            view: (data) => {
                return (<>
                    <span className={`align-middle mr-2 ${data.status === 1 ? "text-success" : "text-danger"}`}>{data.status === 1 ? "已處理" : "尚未處理"}</span>
                    <SwitchToggle name="status" toggle={data.status === 1 ? true : false} callback={() => handleStatus(data.id, data.status)} />
                </>)
            }
        },
        {
            key: 'username',
            name: "會員編號",
        },
        {
            key: 'contents',
            name: "聯絡人",
            view: (data) => {
                return data.contents.contact_name;
            }
        },
        {
            key: 'contents',
            name: "聯絡電話",
            view: (data) => {
                return data.contents.contact_phone;
            }
        },
        {
            key: 'contents',
            name: "Email",
            view: (data) => {
                return data.contents.contact_email;
            }
        },
        {
            key: 'contents',
            name: "姓名 (先生 / 小姐)",
            view: (data) => {
                return data.contents.name;
            }
        },
        {
            key: 'contents',
            name: "來電序號 *(取件序號)",
            view: (data) => {
                return data.contents.order_num;
            }
        },
        {
            key: 'created_at',
            name: "建立時間",
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    <Link to={`/feedback/Cancel/comment/${data.id}`} className="common-btn table-btn green"><i className="fas fa-comment-dots mr-1"></i>查看完整留言</Link>
                </div>
            </>)
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        let resultParams = {...callbackParams, type: "3"};
        setLoader(true);
        let result = await getFeedbackList(resultParams ? resultParams : params);
        setData(result);
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const handleStatus = (id, status) => {
        setLoader(true);
        updateFeedback({id: id, status: status == 0 ? 1 : 0}).then(res => {
            fetchData();
            feedback.setFeedbackRefresh(true);
            setLoader(false);
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const actions = [
        { icon: <CSVLink filename="取消取件.csv" data={exportData} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><GetAppIcon /></CSVLink>, name: '匯出資料'},
    ];

    return (
        <div className="member-list-page">
            <FormTable 
                column={column}
                data={data}
                title={<><i className="fas fa-box-open ml-1 mr-2"></i>取消取件</>}
                checkbox={false}
                maxWidth="unset"
                searchbar={{date: true, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className="tools-btn"
                icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                onClick={() => setOpen(!open)}
                open={open}
            >
                {actions.map((item, key) => 
                <SpeedDialAction
                    key={key}
                    icon={item.icon}
                    tooltipTitle={item.name}
                    tooltipOpen
                />)}
            </SpeedDial>
        </div>
    )
}