import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useForm from "react-hook-form";
import FormGroup from '../../../components/FormGroup';
import ConfirmBox from "../../../components/ConfirmBox";

const data2 = [
    {
        id: 1,
        member_num: "d15as61",
        name: "姓名1",
        line: "",
        bind_at: "",
    },
    {
        id: 2,
        member_num: "e15ws1a",
        name: "姓名2",
        line: "abc123456",
        bind_at: "2021/06/08 22:07:23",
    },
    {
        id: 3,
        member_num: "d45w44a",
        name: "姓名3",
        line: "",
        bind_at: "",
    },
    {
        id: 4,
        member_num: "d1a1s05",
        name: "姓名4",
        line: "abcefghi",
        bind_at: "2021/06/07 20:00:45",
    },
]

export default function Bind(props) {
    let mounted = true;
    const { snack } = props;
    const { id } = useParams();
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ data , setData ] = useState({});
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);

    const fetchData = useCallback(async() => {
        let result = await data2;
        if(mounted) {
            let resultData = result.find(e => e.member_num == id);
            setData(resultData);
        }
    }, [mounted])

    useEffect(() => {
        fetchData();
        return () => {
            mounted = false;
        }
    }, [fetchData])

    const onSubmit = () => {
        let data = getValues();
        console.log(data);
    }

    const handleConfirm = value => {
        setConfirmBoxToggle(value.open);
        if(value.confirm){
            onSubmit();
        }
    }

    const handleCheck = () => {
        let values = getValues();
        if(values.line === ""){
            snack({open: true, text: "請輸入 LINE ID"});
            return;
        }
        setConfirmBoxToggle(true);
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="會員編號">
                        <input
                            type="text"
                            defaultValue={data.member_num}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="會員姓名">
                        <input
                            type="text"
                            defaultValue={data.name}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="綁定 LINE ID" required>
                        <input
                            name="line"
                            type="text"
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="請輸入LINE ID"
                            ref={register}
                        />
                    </FormGroup>
                </div>
                <div className="text-right">
                    <button type="submit" className="submit-btn" onClick={() => handleCheck()}>送出</button>
                </div>
                <ConfirmBox
                    open={confirmBoxToggle}
                    confirmText="確定綁定嗎?"
                    callback={value => handleConfirm(value)}
                    cancel={{show: true, type: "button", text: "取消"}}
                    confirm={{show: true, type: "submit", text: "確定"}}
                />
            </form>
        </div>
    )
}
