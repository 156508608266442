import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import FormTable from '../../../components/FormTables/Table';
import ConfirmBox from "../../../components/ConfirmBox";
import { CSVLink } from "react-csv";

import { SpeedDial } from '@material-ui/lab';
import { SpeedDialIcon } from '@material-ui/lab';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';

import { userList, userUnbind } from '../../../services/authService';

export default function Page(props) {
    let mounted = true;
    const { setSnack, setLoader } = props;
    const [ open, setOpen ] = useState(false);
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);
    const [ submitData, setSubmitData ] = useState({user_id: ""});
    const [ exportData, setExportData ] = useState([]);

    useEffect(() => {
        if(data.data !== undefined) {
            let result = [];
            data.data.map((item, key) => {
                result.push(
                    {
                        "#": item.id,
                        "會員編號": item.username,
                        "會員密碼": item.password,
                        "會員名稱": item.name,
                        "會員電話": item.mobile,
                        "狀態": item.line_user_id !== "" ? "已綁定" : "未綁定",
                        "綁定日期": item.updated_at,
                    }
                )
            })
            setExportData(result);
        }
    }, [data])

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'username',
            name: "會員編號",
        },
        {
            key: 'password',
            name: "會員密碼",
        },
        {
            key: 'name',
            name: "會員名稱",
        },
        {
            key: 'mobile',
            name: "會員電話",
        },
        {
            key: 'line_user_id',
            name: "狀態",
            view: (data) => {
                return data.line_user_id === "" ? <span className="text-danger">未綁定</span> : <span className="text-success">已綁定</span>;
            }
        },
        {
            key: 'updated_at',
            name: "綁定日期",
            view: (data) => {
                return data.updated_at === "" ? "-" : data.updated_at;
            }
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    {
                        data.line_user_id === "" ? (
                            <>-</>
                        ) : (
                            <button className="common-btn table-btn red" onClick={() => handleOpenLightbox({id: data.id})}><i className="fas fa-ban mr-1"></i>解除綁定</button>
                        )
                    }
                </div>
            </>)
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        // let reuslt = await fakeData;
        let result = await userList(callbackParams ? callbackParams : params);
        if(mounted) {
            setData(result);
            setLoader(false);
        }
    }, [mounted]);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const handleOpenLightbox = (data) => {
        setSubmitData(data);
        setConfirmBoxToggle(true);
    }

    const handleConfirm = value => {
        setConfirmBoxToggle(value.open);
        if(value.confirm){
            onSubmit();
        }
    }

    const onSubmit = () => {
        setLoader(true);
        userUnbind(submitData).then(res => {
            fetchData();
            setLoader(false);
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const actions = [
        { icon: <CSVLink filename="會員列表.csv" data={exportData} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><GetAppIcon /></CSVLink>, name: '匯出資料'},
    ];

    return (
        <div className="member-list-page">
            <FormTable 
                column={column}
                data={data}
                title={<><i className="fas fa-users ml-1 mr-2"></i>會員列表</>}
                checkbox={false}
                maxWidth="992px"
                searchbar={{date: false, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className="tools-btn"
                icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                onClick={() => setOpen(!open)}
                open={open}
            >
                {actions.map((item, key) => 
                <SpeedDialAction
                    key={key}
                    icon={item.icon}
                    tooltipTitle={item.name}
                    tooltipOpen
                />)}
            </SpeedDial>
            <ConfirmBox
                open={confirmBoxToggle}
                confirmText="確定解除綁定嗎?"
                callback={value => handleConfirm(value)}
                cancel={{show: true, type: "button", text: "取消"}}
                confirm={{show: true, type: "submit", text: "確定"}}
            />
        </div>
    )
}