import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Menu from './components/Menu';
import Content from './components/Content';
import routes from './components/_nav';
import { FeedbackProvider } from '../store/context/FeedbackContext';

export default function Private(props) {
	const { user, setLoader, children, location } = props;
	const [ menuToggle, setMenuToggle ] = useState(true);
	const [ title, setTitle ] = useState([]);
    const [ main, setMain ] = useState(false);

	useEffect(() => {
        setTitle([]);
        setMain(false);
        let pathname = location.pathname.split('/');
        pathname.shift();

        let titleArray = [];
        
        let isMain = false;
        const filterUrl = (list) => {
            let isChildren = false;
            let isEnd = false;

            list.map(item => {
                pathname.filter(path => {
                    if(item.url === '/'+path) {
                        if(item.children) {
                            // Filter Children
                            let children = filterUrl(item.children);
                            if(children) {
                                isEnd = true;
                            } else {
                                if(isEnd) return;
                                isChildren = true;
                                if(!isMain) {
                                    item.main ? setMain(item.main) : setMain(false);
                                }
                            }
                        } else {
                            isChildren = true;
                            item.main ? setMain(item.main) : setMain(false);
                            isMain = true;
                        }
                        titleArray = [...titleArray,  item.name];
                    }
                });
            });
            return isChildren;
        }
        
        filterUrl(routes);
        setTitle(titleArray.reverse());
    }, [location]);

    return (
        <FeedbackProvider>
            <div className="layout-container">
                <Menu />
                <Header
                    user={user}
                    title={title[title.length-1]}
                    setLoader={setLoader}
                    menuToggle={menuToggle}
                    menuCallback={e => setMenuToggle(e)}
                />
                <Content
                    title={title}
                    main={main}
                    menuCallback={e => setMenuToggle(e)}
                >
                    { children }
                </Content>
            </div>
        </FeedbackProvider>
    )
}