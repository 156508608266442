const navigation = [
    {
        name: '後台管理',
        url: '/member',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '會員列表',
                url: '/List',
                icon: 'fas fa-users',
                main: true,
                children: [
                    {
                        name: '會員 LINE ID 綁定',
                        url: '/bind',
                        icon: 'fas fa-wallet',
                    },
                ]
            },
            {
                name: '瀏覽統計',
                url: '/Statistics',
                icon: 'fas fa-chart-bar',
                main: true,
            },
            // {
            //     name: '意見反應',
            //     url: '/Feedback',
            //     icon: 'fas fa-envelope',
            //     main: true,
            //     children: [
            //         {
            //             name: '查看留言',
            //             url: '/comment',
            //             icon: 'fas fa-wallet',
            //         },
            //     ]
            // },
        ],
    },
    {
        name: '意見反應',
        url: '/feedback',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '物件破損、遺失',
                url: '/Broken',
                icon: 'fas fa-fragile',
                main: true,
                count: {name: "feedback", value: "type1"},
                children: [
                    {
                        name: '查看完整留言',
                        url: '/comment',
                        icon: 'fas fa-wallet',
                    },
                ]
            },
            {
                name: '客訴',
                url: '/Complain',
                icon: 'fas fa-comment-exclamation',
                main: true,
                count: {name: "feedback", value: "type2"},
                children: [
                    {
                        name: '查看完整留言',
                        url: '/comment',
                        icon: 'fas fa-wallet',
                    },
                ]
            },
            {
                name: '取消取件',
                url: '/Cancel',
                icon: 'fas fa-box-open',
                main: true,
                count: {name: "feedback", value: "type3"},
                children: [
                    {
                        name: '查看完整留言',
                        url: '/comment',
                        icon: 'fas fa-wallet',
                    },
                ]
            },
            {
                name: '客戶資料改址',
                url: '/Rewrite',
                icon: 'fas fa-address-book',
                main: true,
                count: {name: "feedback", value: "type4"},
                children: [
                    {
                        name: '查看完整留言',
                        url: '/comment',
                        icon: 'fas fa-wallet',
                    },
                ]
            },
        ],
    },
]


export default navigation;