import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PopoverBox from '../../../../components/PopoverBox';
import ConfirmBox from '../../../../components/ConfirmBox';
import store from '../../../../store';
import { logout } from '../../../../services/authService';
import * as actions from '../../../../store/actions';

const AdminMenu = (props) => {
    const { user, setLoader, setSnack } = props;
    const [ popoverBoxToggle, setPopoverBoxToggle ] = useState(false);
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);

    const handleLogout = () => {
        setPopoverBoxToggle(false);
        setConfirmBoxToggle(true);
    }

    const handleLogoutConfirm = value => {
        setConfirmBoxToggle(value.open);
        if(value.confirm){
            setLoader(true);

            logout().then(res => {
                setLoader(false);
                store.dispatch(actions.authLogout());
                setSnack({open: true, text: "登出成功"});
            }).catch(err => {
                setLoader(false);
            })
        }
    }

    return (<>
        <PopoverBox
            className="popover-box"
            toggle={popoverBoxToggle}
            target="admin-menu"
            callback={() => setPopoverBoxToggle(false)}
            targetItem={<>
                <div className="admin-menu" id="admin-menu" onClick={() => setPopoverBoxToggle(true)}>
                    <i className="fas fa-user mr-2"></i>
                    {/* <div className="shop-logo" style={{backgroundImage: `url(${require(`../../../../../images/web/shop_logo.jpg`).default})`}}></div> */}
                    <span className="name">{user.username ? user.username : "管理員"}</span>
                    <i className="fas fa-caret-down ml-2"></i>
                </div>
            </>}
        >
            <button type="button" onClick={() => handleLogout()}>
                <div className="icon">
                    <i className="fas fa-sign-out"></i>
                </div>
                <span>登出</span>
            </button>
        </PopoverBox>
        <ConfirmBox
            open={confirmBoxToggle}
            confirmText="確定登出嗎?"
            callback={value => handleLogoutConfirm(value)}
            cancel={{show: true, text: "取消"}}
            confirm={{show: true, text: "確定"}}
        />
    </>)
}

export default AdminMenu
