import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useForm from "react-hook-form";
import FormGroup from '../../../components/FormGroup';

const data2 = [
    {
        id: 1,
        username: "180781",
        name: "尚九國際營銷-測試用",
        title: "title1",
        content: "content1",
        created_at: "2021/06/15 12:50:10",
    },
]

export default function Bind(props) {
    let mounted = true;
    const { setSnack } = props;
    const { id } = useParams();
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ data , setData ] = useState({});

    const fetchData = useCallback(async() => {
        let result = await data2;
        if(mounted) {
            let resultData = result.find(e => e.id == id);
            setData(resultData);
        }
    }, [mounted])

    useEffect(() => {
        fetchData();
        return () => {
            mounted = false;
        }
    }, [fetchData])

    return (
        <div className="tools-tags-create-page">
            <form className="common-form">
                <div className="form-container">
                    <FormGroup label="會員編號">
                        <input
                            type="text"
                            defaultValue={data.username}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="會員姓名">
                        <input
                            type="text"
                            defaultValue={data.name}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="標題">
                        <input
                            type="text"
                            defaultValue={data.title}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="內容">
                        <div>{data.content}</div>
                        {/* <textarea
                            type="text"
                            defaultValue={data.content}
                            readOnly
                        /> */}
                    </FormGroup>
                    <FormGroup label="時間">
                        <input
                            type="text"
                            defaultValue={data.created_at}
                            readOnly
                        />
                    </FormGroup>
                </div>
                {/* <div className="text-right">
                    <button type="submit" className="submit-btn" onClick={() => handleCheck()}>送出</button>
                </div> */}
            </form>
        </div>
    )
}