import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import FormTable from '../../../components/FormTables/Table';

import { countList } from '../../../services/authService';

export default function Page(props) {
    const { setLoader } = props;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();

    const fakeData = {
        active_page: 1,
        countPerPage: 10,
        total_rows: 1,
        data: [
            {
                id: 1,
                username: "180781",
                name: "尚九國際營銷-測試用",
                title: "title1",
                content: "content1",
                created_at: "2021/06/15 12:50:10",
            },
        ]
    }

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'username',
            name: "會員編號",
        },
        {
            key: 'name',
            name: "會員名稱",
        },
        {
            key: 'title',
            name: "標題",
        },
        {
            key: 'created_at',
            name: "時間",
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    <Link to={`/member/Feedback/comment/${data.id}`} className="common-btn table-btn green"><i className="fas fa-comment-dots mr-1"></i>查看留言</Link>
                </div>
            </>)
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        // let result = await countList(callbackParams ? callbackParams : params);
        let result = await fakeData;
        setData(result);
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    return (
        <div className="member-list-page">
            <FormTable 
                column={column}
                data={data}
                title={<><i className="fas fa-envelope ml-1 mr-2"></i>意見反應</>}
                checkbox={false}
                maxWidth="unset"
                searchbar={{date: true, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
        </div>
    )
}