import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useForm from "react-hook-form";
import LightBox from "../../../components/LightBox";
import FormGroup from '../../../components/FormGroup';
import { getFeedbackItem } from '../../../services/authService';

export default function Bind(props) {
    let mounted = true;
    const { setSnack } = props;
    const { id } = useParams();
    const [ toggle, setToggle ] = useState(false);
    const [ previewImage, setPreviewImage ] = useState("");
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ data , setData ] = useState({
        contact_email: "",
        contact_name: "",
        contact_phone: "",
        contents: {
            contact_email: "",
            contact_name: "",
            contact_phone: "",
            order_id: "",
            order_info: "",
            order_pic: [],
        },
        created_at: "",
        name: "",
        status: "",
        type: "",
        updated_at: "",
        username: "",
    });

    const fetchData = useCallback(async() => {
        let result = await getFeedbackItem(id);
        if(mounted) {
            setData(result.data);
        }
    }, [mounted])

    useEffect(() => {
        fetchData();
        return () => {
            mounted = false;
        }
    }, [fetchData])

    useEffect(() => {
        if(previewImage !== ""){
            setToggle(true);
        }
    }, [previewImage])

    const handleLightboxCallback = value => {
        setToggle(value);
        setTimeout(() => {
            setPreviewImage("");
        }, 200)
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form">
                <div className="form-container">
                    <FormGroup label="狀態">
                        {data.status === 1 ? "已處理" : "尚未處理"}
                    </FormGroup>
                    <FormGroup label="訂單編號">
                        <input
                            type="text"
                            defaultValue={data.contents.order_id}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="會員編號">
                        <input
                            type="text"
                            defaultValue={data.username}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="聯絡人">
                        <input
                            type="text"
                            defaultValue={data.contact_name}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="聯絡電話">
                        <input
                            type="text"
                            defaultValue={data.contact_phone}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="Email">
                        <input
                            type="text"
                            defaultValue={data.contents.contact_email}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="收件資料">
                        <div>{data.contents.order_info}</div>
                    </FormGroup>
                    <FormGroup label="破損照">
                        { data.contents.order_pic.map((item, key) => <img className="mr-2 mb-2" style={{cursor: "pointer"}} src={`https://linebot.sun9group.com/uploads/feedback/${item}`} height="200" key={key} onClick={() => setPreviewImage(`https://linebot.sun9group.com/uploads/feedback/${item}`)} />) }
                    </FormGroup>
                    <FormGroup label="影片">
                        <video width="100%" height="auto" preload="none" className="mb-2" src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" controls></video>
                        <video width="100%" height="auto" preload="none" className="mb-2" src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4" controls></video>
                        <video width="100%" height="auto" preload="none" className="mb-2" src="http://www.exit109.com/~dnn/clips/RW20seconds_1.mp4" controls></video>
                    </FormGroup>
                    <FormGroup label="建立時間">
                        <input
                            type="text"
                            defaultValue={data.created_at}
                            readOnly
                        />
                    </FormGroup>
                </div>
            </form>
            <LightBox open={toggle} cross={true} width="600px" callback={({open}) => handleLightboxCallback(open)}>
                <div className="light-box-body">
                    <img src={previewImage} alt="" className="w-100 d-block" style={{verticalAlign: "top"}} />
                </div>
            </LightBox>
        </div>
    )
}