import React from 'react';

const FormGroup = (props) => {
    const { label, required, desc, children } = props;

    return (
        <div className="form-group">
            <div className="label-group">
                <div className="d-flex align-items-center">
                    <label>{ label }</label>
                </div>
                { required && <div className="text-secondary font-size-sm">( 必填項目 )</div> }
            </div>
            <div className="input-group">
                { children }
                { desc && <div className="input-desc">{ desc }</div> }
            </div>
        </div>
    )
}

export default FormGroup;
