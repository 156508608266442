import React, {useState, useEffect} from 'react';

const SwitchToggle = ({ name, toggle, callback }) => {
    const [ switchToggle, setSwitchToggle] = useState(false);
    
    useEffect(() => {
        setSwitchToggle(toggle);
    }, [toggle])

    const handleSwitchToggle = () => {
        let result = !switchToggle;
        setSwitchToggle(result);
        callback();
    }

    return (
        <label className="switch d-inline-block align-middle">
            <input name={name} type="checkbox" checked={switchToggle} onChange={() => handleSwitchToggle()} />
            <span className="slider round"></span>
        </label>
    );
}

export default SwitchToggle;
