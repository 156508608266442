import Http from "../utils/Http";
import * as action from "../store/actions";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const API_URL = process.env.REACT_APP_API_URL;
const API_BASIC = process.env.REACT_APP_API_BASIC;

export function login(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/auth/login", credentials)
			.then(res => {
				return resolve(res.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function logout() {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/logout")
			.then(res => {
				return resolve(res.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
				// console.clear();
	});
}

export function refreshToken() {

	let formData = new FormData();
	formData.append("grant_type", "refresh_token");
	formData.append("refresh_token", localStorage.getItem('refresh_token'));

	return dispatch =>
		new Promise((resolve, reject) => {
			Http.post(API_URL+"/auth/refreshToken", formData)
				.then(res => {
					dispatch(action.authRefreshToken(res.data));
					return resolve(res.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
		});
}

export function fetchUser() {
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.get(API_URL+"/auth/user")
				.then(res => {
					dispatch(action.authUser(res.data));
					return resolve();
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err);
					}
				});
		});
}

//首頁---------------------------------------------------------------------------------------
export function getDashboard() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/auth/dashboard")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//-------------------------------------------------------------------------------------------

//管理者列表---------------------------------------------------------------------------------------
export function getAdminList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/auth/admin", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//------------------------------------------------------------------------------------------------

//會員列表---------------------------------------------------------------------------------------
export function userList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function userUnbind(credentials) {
	return new Promise((resolve, reject) => {
			Http.post(API_URL+"/users/unbind", credentials)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}

export function getUserItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/auth/user/"+ id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserTeam(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/auth/team/"+ id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUser(id, credentials) {
	
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/auth/user/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

//瀏覽統計---------------------------------------------------------------------------------------
export function countList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/count", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

//意見反應---------------------------------------------------------------------------------------

export function getFeedbackCount() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/feedback/count")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getFeedbackList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/feedback", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getFeedbackItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/feedback/"+ id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateFeedback(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/feedback/status", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

//銀行審核---------------------------------------------------------------------------------------
export function getUserBank(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/auth/user/bank", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}


export function verifyBank(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/auth/user/bank/verify", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//身份證審核---------------------------------------------------------------------------------------
export function getUserIDCard(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/auth/user/idcard", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function verifyIDCard(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/auth/user/idcard/verify", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------


//用戶資產管理---------------------------------------------------------------------------------------
export function getUserWallet(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/auth/user/wallet", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//發送貨幣---------------------------------------------------------------------------------------

export function getSendCurrencyList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/auth/transaction/issuance", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUser(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/auth/user/getUserByUsername", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function sendWalletCurrency(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/auth/transaction/issuance", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

//--------------------------------------------------------------------------------------------------
