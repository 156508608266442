import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useForm from "react-hook-form";
import FormGroup from '../../../components/FormGroup';
import { getFeedbackItem } from '../../../services/authService';

export default function Bind(props) {
    let mounted = true;
    const { setSnack } = props;
    const { id } = useParams();
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ data , setData ] = useState({
        contact_email: "",
        contact_name: "",
        contact_phone: "",
        contents: {
            contact_email: "",
            contact_name: "",
            contact_phone: "",
            name: "",
            order_num: "",
        },
        created_at: "",
        name: "",
        status: "",
        type: "",
        updated_at: "",
        username: "",
    });

    const fetchData = useCallback(async() => {
        let result = await getFeedbackItem(id);
        if(mounted) {
            setData(result.data);
        }
    }, [mounted])

    useEffect(() => {
        fetchData();
        return () => {
            mounted = false;
        }
    }, [fetchData])

    return (
        <div className="tools-tags-create-page">
            <form className="common-form">
                <div className="form-container">
                    <FormGroup label="狀態">
                        {data.status === 1 ? "已處理" : "尚未處理"}
                    </FormGroup>
                    <FormGroup label="會員編號">
                        <input
                            type="text"
                            defaultValue={data.username}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="聯絡人">
                        <input
                            type="text"
                            defaultValue={data.contact_name}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="聯絡電話">
                        <input
                            type="text"
                            defaultValue={data.contact_phone}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="Email">
                        <input
                            type="text"
                            defaultValue={data.contents.contact_email}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="姓名 (先生 / 小姐)">
                        <input
                            type="text"
                            defaultValue={data.contents.name}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="來電序號 *(取件序號)">
                        <input
                            type="text"
                            defaultValue={data.contents.order_num}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="建立時間">
                        <input
                            type="text"
                            defaultValue={data.created_at}
                            readOnly
                        />
                    </FormGroup>
                </div>
            </form>
        </div>
    )
}