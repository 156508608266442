import Login from '../modules/Auth/Login';

// Member----------------------------------------------
import MemberList from '../modules/Member/List';
import MemberListBind from '../modules/Member/List/Bind';
import MemberStatistics from '../modules/Member/Statistics';
import MemberFeedback from '../modules/Member/Feedback';
import MemberFeedbackComment from '../modules/Member/Feedback/Comment';

// Feedback----------------------------------------------
import FeedbackBroken from '../modules/Feedback/Broken';
import FeedbackBrokenComment from '../modules/Feedback/Broken/Comment';
import FeedbackComplain from '../modules/Feedback/Complain';
import FeedbackComplainComment from '../modules/Feedback/Complain/Comment';
import FeedbackCancel from '../modules/Feedback/Cancel';
import FeedbackCancelComment from '../modules/Feedback/Cancel/Comment';
import FeedbackRewrite from '../modules/Feedback/Rewrite';
import FeedbackRewriteComment from '../modules/Feedback/Rewrite/Comment';

const routes = [
    {
        path: '/',
        exact: true,
        auth: false,
        component: Login
    },
    {
        path: '/login',
        exact: true,
        auth: false,
        component: Login
    },
    {
        path: '/member/List',
        exact: true,
        auth: true,
        component: MemberList,
    },
    {
        path: '/member/List/bind/:id',
        exact: true,
        auth: true,
        component: MemberListBind,
    },
    {
        path: '/member/Statistics',
        exact: true,
        auth: true,
        component: MemberStatistics,
    },
    {
        path: '/member/Feedback',
        exact: true,
        auth: true,
        component: MemberFeedback,
    },
    {
        path: '/member/Feedback/comment/:id',
        exact: true,
        auth: true,
        component: MemberFeedbackComment,
    },
    {
        path: '/feedback/Broken',
        exact: true,
        auth: true,
        component: FeedbackBroken,
    },
    {
        path: '/feedback/Broken/comment/:id',
        exact: true,
        auth: true,
        component: FeedbackBrokenComment,
    },
    {
        path: '/feedback/Complain',
        exact: true,
        auth: true,
        component: FeedbackComplain,
    },
    {
        path: '/feedback/Complain/comment/:id',
        exact: true,
        auth: true,
        component: FeedbackComplainComment,
    },
    {
        path: '/feedback/Cancel',
        exact: true,
        auth: true,
        component: FeedbackCancel,
    },
    {
        path: '/feedback/Cancel/comment/:id',
        exact: true,
        auth: true,
        component: FeedbackCancelComment,
    },
    {
        path: '/feedback/Rewrite',
        exact: true,
        auth: true,
        component: FeedbackRewrite,
    },
    {
        path: '/feedback/Rewrite/comment/:id',
        exact: true,
        auth: true,
        component: FeedbackRewriteComment,
    },
];

export default routes;