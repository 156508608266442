import React , { useState, useEffect } from 'react';
import { getFeedbackCount } from '../../services/authService';

const initialState = {
    feedbackRefresh: true,
    setFeedbackRefresh: state => {},
    feedbackCount: {
        type1: "0",
        type2: "0",
        type3: "0",
        type4: "0",
    },
    setFeedbackCount: state => {},
}

export const FeedbackContext = React.createContext(initialState);

export const FeedbackProvider = (props) => {
    let mounted = true;
    const [ feedbackCount, setFeedbackCount ] = useState(initialState.feedbackCount);
    const [ feedbackRefresh, setFeedbackRefresh ] = useState(initialState.feedbackRefresh);
    
    const fetchData = async() => {
        let result = await getFeedbackCount();
        if(result) {
            setFeedbackCount(result.data);
            setFeedbackRefresh(false);
        }
    }

    useEffect(() => {
        if(feedbackRefresh) {
            fetchData();
        }
        return () => {
            mounted = false;
        }
    }, [feedbackRefresh, fetchData])

    return (
        <FeedbackContext.Provider value={{feedbackCount, feedbackRefresh, setFeedbackCount, setFeedbackRefresh}}>
            { props.children }
        </FeedbackContext.Provider>
    )
}