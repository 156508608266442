import React, { useState, useEffect, useCallback } from 'react';
import FormTable from '../../../components/FormTables/Table';
import { CSVLink } from "react-csv";

import { SpeedDial } from '@material-ui/lab';
import { SpeedDialIcon } from '@material-ui/lab';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';

import { countList } from '../../../services/authService';

export default function Page(props) {
    const { setLoader } = props;
    const [ open, setOpen ] = useState(false);
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();
    const [ exportData, setExportData ] = useState([]);

    useEffect(() => {
        if(data.data !== undefined) {
            let result = [];
            data.data.map((item, key) => {
                result.push(
                    {
                        "#": item.id,
                        "日期": item.date,
                        "綁定人數": item.user,
                        "收件數": item.receive,
                        "查件數 (含簽單)": item.check,
                        "貨件查詢": item.search,
                        "訂購便利帶": item.buy,
                        "消息公告": item.news,
                        "常見問題": item.faq,
                        "意見反應": item.feedback,
                    }
                )
            })
            setExportData(result);
        }
    }, [data])

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'date',
            name: "日期",
        },
        {
            key: 'user',
            name: "綁定次數",
        },
        {
            key: 'receive',
            name: "收件數",
        },
        {
            key: 'check',
            name: "查件數 (含簽單)",
        },
        {
            key: 'search',
            name: "貨件查詢",
        },
        {
            key: 'buy',
            name: "訂購便利帶",
        },
        {
            key: 'news',
            name: "消息公告",
        },
        {
            key: 'faq',
            name: "常見問題",
        },
        {
            key: 'feedback',
            name: "意見反應",
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        let result = await countList(callbackParams ? callbackParams : params);
        // let reuslt = await fakeData;
        setData(result);
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const actions = [
        { icon: <CSVLink filename="瀏覽統計.csv" data={exportData} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><GetAppIcon /></CSVLink>, name: '匯出資料'},
    ];

    return (
        <div className="member-list-page">
            <FormTable 
                column={column}
                data={data}
                title={<><i className="fas fa-chart-bar ml-1 mr-2"></i>瀏覽統計</>}
                checkbox={false}
                maxWidth="unset"
                searchbar={{date: true, keyword: false}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className="tools-btn"
                icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                onClick={() => setOpen(!open)}
                open={open}
            >
                {actions.map((item, key) => 
                <SpeedDialAction
                    key={key}
                    icon={item.icon}
                    tooltipTitle={item.name}
                    tooltipOpen
                />)}
            </SpeedDial>
        </div>
    )
}